import { SET_LOADING, SET_REMOVING, SET_ACTIVE_RETAILER } from './types';

const setActiveRetailer = (
  state,
  { retailer: { name, displayName, logo, deepLinkUrl }, index, total }
) => ({
  ...state,
  activeRetailer: {
    index,
    total,
    name,
    displayName,
    logo,
    deepLinkUrl,
  },
});

const setLoading = (state, { loading }) => ({
  ...state,
  loading,
});

const setRemovingState = (state, { removing, ean }) => ({
  ...state,
  removeState: {
    removing,
    ean,
  },
});

const reducer = {
  [SET_LOADING]: setLoading,
  [SET_ACTIVE_RETAILER]: setActiveRetailer,
  [SET_REMOVING]: setRemovingState,
};

const initialState = {
  loading: false,
};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
