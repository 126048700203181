import {
  SET_STEP,
  SET_SELECTED_PATH,
  SET_ANSWERS,
  SET_SELECTOR_OPTIONS,
  SET_CHANGE_PATH_VALUES,
  SET_PACKSHOT_ID,
  SET_QUIZ_TYPE,
  LOADING,
} from './actions';

const currentStepNumberState = (state, { currentStepNumber }) => ({
  ...state,
  currentStepNumber,
});

const selectedPathState = (state, { selectedPath }) => ({
  ...state,
  selectedPath,
});

const answersState = (state, { answers }) => ({
  ...state,
  answers,
});

const selectorsOptionsState = (state, { data }) => ({
  ...state,
  selectorsOptions: data,
});

const changePathState = (state, { changePathValues }) => ({
  ...state,
  changePathValues,
});

const packshotIdState = (state, { packshotId }) => ({
  ...state,
  packshotId,
});

const quizTypeState = (state, { quizType }) => ({
  ...state,
  quizType,
});

const loadingState = (state, { loading }) => ({
  ...state,
  loading,
});

const reducer = {
  [SET_STEP]: currentStepNumberState,
  [SET_SELECTED_PATH]: selectedPathState,
  [SET_ANSWERS]: answersState,
  [SET_SELECTOR_OPTIONS]: selectorsOptionsState,
  [SET_CHANGE_PATH_VALUES]: changePathState,
  [SET_PACKSHOT_ID]: packshotIdState,
  [SET_QUIZ_TYPE]: quizTypeState,
  [LOADING]: loadingState,
};

const initialState = {
  currentStepNumber: 1,
  selectedPath: null,
  answers: [],
  selectorsOptions: [],
  changePathValues: {
    filteredProductsVariants: [],
    answersVariants: [],
    shadeAnswersStepNumber: 0,
    sortedSelectorsOptions: [],
  },
  packshotId: '',
  quizType: '',
  loading: false,
};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
