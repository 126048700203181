const getItemIndex = (items, ean) =>
  Array.isArray(items)
    ? items.reduce((acc, value, index) => {
        if (value.ean === ean) {
          return index;
        }
        return acc;
      }, -1)
    : -1;

const loadBasketState = () => {
  try {
    const serializedBasket = localStorage.getItem('state');
    if (serializedBasket === null) return undefined;
    return JSON.parse(serializedBasket);
  } catch (err) {
    return undefined;
  }
};

const saveBasketState = (basket) => {
  try {
    const serializedBasket = JSON.stringify(basket);
    localStorage.setItem('state', serializedBasket);
  } catch (err) {
    //
  }
};

export { getItemIndex, loadBasketState, saveBasketState };
