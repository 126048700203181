import { TOGGLE_SUBCATEGORY_NAVIGATION } from './actions';

const toggle = (state) => ({
  ...state,
  isOpen: !state.isOpen,
});

const reducer = {
  [TOGGLE_SUBCATEGORY_NAVIGATION]: toggle,
};

const initialState = {};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
