import R from 'ramda';
import {
  SET_ERROR,
  CLEAR_ERROR,
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  UPDATE_BASKET_ITEM_QUANTITY,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_PRODUCT_QUANTITY,
  UPDATE_BASKET,
} from './types';
import { getItemIndex, loadBasketState } from '../utils';

const updateBasket = (state, { basket }) => ({
  ...state,
  ...basket,
});

const setError = (state, { message }) => ({
  ...state,
  error: {
    message,
  },
});

const clearError = (state) => R.omit(['error'], state);

const addToBasket = (state, { item }) => {
  const items = Array.isArray(state.items) ? state.items : [];

  const itemIndex = getItemIndex(state.items, item.ean);
  if (itemIndex === -1) {
    // New item
    return {
      ...state,
      items: [...items, item],
    };
  }

  return {
    ...state,
    items: items.map((value, index) =>
      index === itemIndex
        ? {
            ...value,
            quantity: value.quantity + item.quantity,
          }
        : value
    ),
  };
};

const removeFromBasket = (state, { ean }) => ({
  ...state,
  items: Array.isArray(state.items)
    ? state.items.filter((item) => item.ean !== ean)
    : [],
});

const updateBasketItemQuantity = (state, { ean, quantity }) => ({
  ...state,
  items: Array.isArray(state.items)
    ? state.items.map((item) =>
        item.ean === ean ? { ...item, quantity } : item
      )
    : [],
});

const showModal = (state) => ({
  ...state,
  modal: {
    isShown: true,
  },
});

const hideModal = (state) => ({
  ...state,
  modal: {
    isShown: false,
  },
});

const setProductQuantity = (state, { quantity }) => ({
  ...state,
  quantity,
});

const reducer = {
  [SET_ERROR]: setError,
  [CLEAR_ERROR]: clearError,
  [ADD_TO_BASKET]: addToBasket,
  [REMOVE_FROM_BASKET]: removeFromBasket,
  [UPDATE_BASKET_ITEM_QUANTITY]: updateBasketItemQuantity,
  [SHOW_MODAL]: showModal,
  [HIDE_MODAL]: hideModal,
  [SET_PRODUCT_QUANTITY]: setProductQuantity,
  [UPDATE_BASKET]: updateBasket,
};

const defaultState = {
  items: loadBasketState(),
  loading: true,
};

export default (state = defaultState || {}, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
