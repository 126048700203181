export const SET_STEP = 'BEAMLY/CCX/SET_STEP';
export const SET_SELECTED_PATH = 'BEAMLY/CCX/SET_PATH';
export const SET_ANSWERS = 'BEAMLY/CCX/SET_ANSWERS';
export const SET_SELECTOR_OPTIONS = 'BEAMLY/CCX/SET_SELECTOR_OPTIONS';
export const SET_CHANGE_PATH_VALUES = 'BEAMLY/CCX/SET_CHANGE_PATH_VALUES';
export const SET_PACKSHOT_ID = 'BEAMLY/CCX/SET_PACKSHOT_ID';
export const SET_QUIZ_TYPE = 'BEAMLY/CCX/SET_QUIZ_TYPE';
// Paths
export const FIND_MY_SHADE = 'FIND_MY_SHADE';
export const MATCH_MY_SHADE = 'MATCH_MY_SHADE';
export const EMAIL = 'EMAIL';
export const RESULT = 'RESULT';
export const LOADING = 'BEAMLY/CCX/LOADING';

export const setStep = ({ currentStepNumber }) => ({
  type: SET_STEP,
  currentStepNumber,
});

export const setSelectedPath = ({ selectedPath }) => ({
  type: SET_SELECTED_PATH,
  selectedPath,
});

export const setAnswers = ({ answers }) => ({
  type: SET_ANSWERS,
  answers,
});

export const setSelectorsOptions = ({ data }) => ({
  type: SET_SELECTOR_OPTIONS,
  data,
});

export const setChangePathValues = ({ changePathValues }) => ({
  type: SET_CHANGE_PATH_VALUES,
  changePathValues,
});

export const setPackshotId = ({ packshotId }) => ({
  type: SET_PACKSHOT_ID,
  packshotId,
});

export const setQuizType = ({ quizType }) => ({
  type: SET_QUIZ_TYPE,
  quizType,
});

export const setLoading = ({ loading }) => ({
  type: LOADING,
  loading,
});

export const getMatchMyShadeResult =
  ({ matchMyShadeVariantUid, locale }) =>
  (dispatch) => {
    const dispatchLoading = ({ loading }) =>
      dispatch(
        setLoading({
          loading,
        })
      );

    dispatchLoading({ loading: true });

    return fetch(
      `/${locale}/api/match-my-shade-product-result/${matchMyShadeVariantUid}`
    ).then((res) => {
      dispatchLoading({ loading: false });

      return res.json();
    });
  };

export const getFindMyShadeResult =
  ({ findMyShadeVariantUid, locale }) =>
  (dispatch) => {
    const dispatchLoading = ({ loading }) =>
      dispatch(
        setLoading({
          loading,
        })
      );

    dispatchLoading({ loading: true });

    return fetch(
      `/${locale}/api/find-my-shade-product-result/${findMyShadeVariantUid}`
    ).then((res) => {
      dispatchLoading({ loading: false });

      return res.json();
    });
  };
