import {
  SET_IS_SUBSCRIBE_EMAIL,
  SET_IS_PRODUCT_EMAIL,
  SET_REQUEST_STATUS,
  RESET_SUBMIT_PRODUCT_EMAIL_STATE,
} from './actions';

export const initialState = {
  isSubscribeEmail: false,
  isProductEmail: false,
  status: null,
};

const isSubscribeEmailState = (state, { value }) => ({
  ...state,
  isSubscribeEmail: value,
});

const isProductEmailState = (state, { value }) => ({
  ...state,
  isProductEmail: value,
});

const statusState = (state, { status }) => ({
  ...state,
  status,
});

const resetState = () => initialState;

const reducer = {
  [SET_IS_SUBSCRIBE_EMAIL]: isSubscribeEmailState,
  [SET_IS_PRODUCT_EMAIL]: isProductEmailState,
  [SET_REQUEST_STATUS]: statusState,
  [RESET_SUBMIT_PRODUCT_EMAIL_STATE]: resetState,
};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
