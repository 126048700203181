import { createSelector } from 'reselect';
import R from 'ramda';
// import { generateRandomNumber } from '../utils'; // ! TODO Figure out why this cause the reorder bug

const getProductRetailersContainingProduct = (product) => {
  if (!product || !Array.isArray(product.retailers)) {
    return [];
  }

  return product.retailers.reduce(
    (retailers, retailer) =>
      retailers.concat([
        {
          ...retailer,
          products: R.map(
            (productItem) => ({
              ...productItem,
              price: retailer.price,
            }),
            [R.omit(['retailers'], product)]
          ),
        },
      ]),
    []
  );
};

const mergeRetailerProducts = (retailers, productRetailer) =>
  retailers.reduce((mergedRetailers, retailer) => {
    if (retailer.name === productRetailer.name) {
      const mergedProducts = retailer.products.concat(productRetailer.products);
      return mergedRetailers.concat({ ...retailer, products: mergedProducts });
    }
    return mergedRetailers.concat(retailer);
  }, []);

/**
 * Merge the two arrays of retailers and a specific products retailers, concatenating the products when the same
 * retailer exists in both arrays.
 */
const mergeRetailersContainingProducts = (retailers, productRetailers) => {
  if (retailers.length === 0) {
    return productRetailers;
  }

  if (productRetailers.length === 0) {
    return retailers;
  }

  return productRetailers.reduce((mergedRetailers, productRetailer) => {
    if (
      mergedRetailers.filter(
        (retailer) => retailer.name === productRetailer.name
      ).length === 0
    ) {
      // Retailer is not in merged retailers list, so add it to end
      return mergedRetailers.concat(productRetailer);
    }

    // Retailer is in merged retailers list, so merge the retailers' products with new product
    return mergeRetailerProducts(mergedRetailers, productRetailer);
  }, retailers);
};

// ! TODO Fix the randomise tabs reordering bug
// export const randomiseRetailers = retailers => retailers.sort(() => 0.5 - generateRandomNumber());

const getRetailersWithProductsImpl = (products) => {
  if (!Array.isArray(products)) {
    return [];
  }

  // Derive retailers containing products
  const unsortedRetailers = products.reduce((retailers, product) => {
    const productRetailers = getProductRetailersContainingProduct(product);

    return mergeRetailersContainingProducts(retailers, productRetailers);
  }, []);

  return unsortedRetailers;
};

export const getRetailersWithProducts = createSelector(
  [(state) => state.basket.items],
  getRetailersWithProductsImpl
);

export const getStock = createSelector(
  [(state) => state.basket.items],
  (items) => (Array.isArray(items) ? items.length : undefined)
);

const getTotalPricePerProducts = (retailer) =>
  retailer.products
    .map((product) => parseFloat(product.price * product.quantity))
    .reduce((acc, num) => acc + num)
    .toFixed(2);

export const getTotalPrice = createSelector(
  [(state) => state.basket.items],
  (items) => {
    const retailers = getRetailersWithProductsImpl(items);

    return retailers.map(getTotalPricePerProducts);
  }
);

export const getProductsCount = createSelector(
  [(state) => state.basket.items, (state) => state.shoppingBag.activeRetailer],
  (products, activeRetailer) => {
    if (!activeRetailer) {
      return 0;
    }

    const retailers = getRetailersWithProductsImpl(products);

    return retailers
      .filter((retailer) => retailer.displayName === activeRetailer.displayName)
      .map((retailer) => retailer.products)
      .filter((product) => product.length).length;
  }
);

export const getSubTotalPrice = (product) =>
  `${product.currency}${(product.price * product.quantity).toFixed(2)}`;

export const getCartItems = createSelector(
  [(state) => state.basket.items, (state) => state.shoppingBag.activeRetailer],
  (products, activeRetailer) => {
    if (!activeRetailer) {
      return 0;
    }
    const retailersWithProducts = getRetailersWithProductsImpl(products);

    const retailerProducts = R.compose(
      R.defaultTo([]),
      R.prop('products'),
      R.nth(0),
      R.filter(
        (retailer) => retailer.displayName === activeRetailer.displayName
      )
    )(retailersWithProducts);

    return retailerProducts.map(
      ({
        quantity,
        sku,
        name,
        productId,
        category,
        subcategory,
        shadeName,
        price,
      }) => ({
        quantity,
        basePrice: R.compose(R.multiply(quantity), parseFloat)(price),
        productInfo: {
          sku,
          productName: name,
          productID: productId,
          variants: {
            color: shadeName,
            size: 'N/A',
            type: subcategory,
          },
          category,
        },
      })
    );
  }
);
