import { TOGGLE_INGREDIENTS } from './actions';

const toggle = (state) => ({
  ...state,
  isIngredientsOpen: !state.isIngredientsOpen,
});

const reducer = {
  [TOGGLE_INGREDIENTS]: toggle,
};

const initialState = {};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
