export const SET_IS_SUBSCRIBE_EMAIL = 'BEAMLY/CCX/IS_SUBSCRIBE_EMAIL';
export const SET_IS_PRODUCT_EMAIL = 'BEAMLY/CCX/IS_PRODUCT_EMAIL';
export const SET_REQUEST_STATUS = 'BEAMLY/CCX/SET_REQUEST_STATUS';
export const RESET_SUBMIT_PRODUCT_EMAIL_STATE =
  'BEAMLY/CCX/RESET_SUBMIT_PRODUCT_EMAIL_STATE';

export const PENDING_STATUS = 'PENDING';
export const SUCCESS_STATUS = 'SUCCESS';
export const ERROR_STATUS = 'ERROR';

export const setIsSubscribeEmail = ({ value }) => ({
  type: SET_IS_SUBSCRIBE_EMAIL,
  value,
});

export const setIsProductEmail = ({ value }) => ({
  type: SET_IS_PRODUCT_EMAIL,
  value,
});

export const setRequestStatus = ({ status }) => ({
  type: SET_REQUEST_STATUS,
  status,
});

export const requestStart =
  ({ url, data }) =>
  async (dispatch) => {
    dispatch(setRequestStatus({ status: PENDING_STATUS }));

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });

    if (response.ok) {
      dispatch(setRequestStatus({ status: SUCCESS_STATUS }));
    } else {
      dispatch(setRequestStatus({ status: ERROR_STATUS }));
    }

    return response;
  };

export const resetSubmitProductEmailState = () => ({
  type: RESET_SUBMIT_PRODUCT_EMAIL_STATE,
});
