import { createSelector } from 'reselect';

const getErrorMessage = createSelector(
  [(state) => state.basket.error],
  (data) => (!data.message ? undefined : data.message)
);

const getBasketQuantity = createSelector(
  [(state) => state.basket.items],
  (items) => {
    if (!Array.isArray(items)) {
      return 0;
    }

    return items.reduce(
      (acc, value) =>
        value.quantity ? acc + parseInt(value.quantity, 10) : acc,
      0
    );
  }
);

export { getErrorMessage, getBasketQuantity };
