import R from 'ramda';
import {
  getTotalPrice,
  getRetailersWithProducts,
} from '../molecules/shopping-bag/modules/selectors';
import { getBasketQuantity } from '../molecules/basket/modules/selectors';

export const isXSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('xsmall');

export const isSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('small');

export const isMediumBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('medium');

export const isLargeBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('large');

export const getCarts = (state, retailersWithProducts, cartID) =>
  Array.isArray(retailersWithProducts)
    ? retailersWithProducts.map((retailer, index) => ({
        subcartID: `${cartID}_${index + 1}`,
        cartIndex: `${index + 1} of ${R.length(retailersWithProducts)}`,
        cartsumTotal: getTotalPrice(state)[index],
        cartsummaryQty: getBasketQuantity(state),
        voucherDiscount: 'N/A',
        retailer: R.prop('name', retailer),
        item: R.compose(
          R.map((product) => ({
            quantity: product.quantity,
            basePrice: `${parseFloat(product.price) * product.quantity}`,
            productInfo: {
              sku: product.sku,
              productName: product.name,
              productID: product.productId,
              unitPrice: product.price,
              variants: {
                color: product.shadeName,
                size: 'N/A',
                type: product.subcategory,
              },
              category: product.category,
            },
          })),
          R.prop('products')
        )(retailer),
      }))
    : [];

export const getCartDigitalDataPayload = (state) => {
  const retailersWithProducts = getRetailersWithProducts(state);
  const cartID = R.path(['basket', 'cartID'], state);

  return {
    subcartsTotal: R.length(retailersWithProducts),
    cartID,
    carts: getCarts(state, retailersWithProducts, cartID),
  };
};
