const SET_ERROR = 'BEAMLY/BASKET/SET_ERROR';
const CLEAR_ERROR = 'BEAMLY/BASKET/CLEAR_ERROR';
const ADD_TO_BASKET = 'BEAMLY/BASKET/ADD_TO_BASKET';
const REMOVE_FROM_BASKET = 'BEAMLY/BASKET/REMOVE_FROM_BASKET';
const UPDATE_BASKET_ITEM_QUANTITY = 'BEAMLY/BASKET/UPDATE_BASKET_ITEM_QUANTITY';
const SHOW_MODAL = 'BEAMLY/BASKET/SHOW_MODAL';
const HIDE_MODAL = 'BEAMLY/BASKET/HIDE_MODAL';
const SET_PRODUCT_QUANTITY = 'BBEAMLY/BASKET/SET_PRODUCT_QUANTITY';
const UPDATE_BASKET = 'BEAMLY/BASKET/UPDATE_BASKET';

export {
  SET_ERROR,
  CLEAR_ERROR,
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  UPDATE_BASKET_ITEM_QUANTITY,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_PRODUCT_QUANTITY,
  UPDATE_BASKET,
};
