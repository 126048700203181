import * as R from 'ramda/dist/ramda';
import { trackBuyFromRetailerEvent } from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/basket';

import { SET_LOADING, SET_REMOVING, SET_ACTIVE_RETAILER } from './types';
import {
  handleFetchResponse,
  normalizeItems,
  redirectToUrl,
  readJSONFromScriptTag,
  getCurrencyCode,
} from '../utils';
import { getBasketQuantity } from '../../basket/modules/selectors';
import { getCartItems, getTotalPrice } from './selectors';

const { CHANNEL_ADVISOR_CART_URL } = readJSONFromScriptTag({ id: 'clientEnv' });

export const getPurchaseUrlAsync =
  ({ retailerName, items }) =>
  async () => {
    if (!retailerName || !items) {
      return;
    }

    // AuthorizationToken, Method, and CartId added by proxied server call
    try {
      const response = await fetch(`${CHANNEL_ADVISOR_CART_URL}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          RetailerName: retailerName,
          items: normalizeItems(items),
          Method: 'AddMultiple',
          CartId: '',
        }),
      });

      const validatedResponse = await handleFetchResponse(response);

      if (
        !validatedResponse ||
        !validatedResponse.Cart ||
        !validatedResponse.Cart.PurchaseUrl
      ) {
        console.warn('No purchase url given');
      } else {
        redirectToUrl(validatedResponse.Cart.PurchaseUrl);
      }
    } catch (err) {
      console.error(err.statusText);
    }
  };

export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setActiveRetailer = ({ retailer, index, total }) => ({
  type: SET_ACTIVE_RETAILER,
  retailer,
  index,
  total,
});

export const setRemovingState = ({ removing, ean }) => ({
  type: SET_REMOVING,
  removing,
  ean,
});

export const trackBuyFromRetailer = () => (dispatch, getState) => {
  const state = getState();
  const index = R.path(['shoppingBag', 'activeRetailer', 'index'], state);
  const items = R.pathOr([], ['basket', 'items'], state);

  trackBuyFromRetailerEvent({
    currencyCode: getCurrencyCode({
      retailers: R.path(['0', 'retailers'], items),
    }),
    displayName: R.path(
      ['shoppingBag', 'activeRetailer', 'displayName'],
      state
    ),
    url: R.path(['shoppingBag', 'activeRetailer', 'deepLinkUrl'], state),
    total: R.path(['shoppingBag', 'activeRetailer', 'total'], state),
    name: R.path(['shoppingBag', 'activeRetailer', 'name'], state),
    cartID: R.path(['basket', 'cartID'], state),
    grandTotal: getTotalPrice(state)[index],
    sumQty: getBasketQuantity(state),
    cartItem: getCartItems(state),
    cartIndex: index,
  });
};
