import R from 'ramda';

export const handleFetchResponse = (response) => {
  if (response.status >= 400) {
    throw response;
  }

  return response.json();
};

// We are assuming all items should be of same currency
export const getCurrencySymbol = (products) =>
  products
    .map((product) => product.currency)
    .reduce((acc, curr) => {
      if (curr) return curr;
      return acc;
    });

export const normalizeItems = (items) =>
  !Array.isArray(items)
    ? []
    : items.map((item) => ({
        CartId: '',
        ModelName: item.ean,
        Quantity: item.quantity,
      }));

export const redirectToUrl = (url) => window.location.assign(url);

// Used for test
export const generateRandomNumber = () => Math.random();

export const analyticsDecorator = (name, action, additional) => ({
  dataTrackable: `${name}-${action}`,
  dataClickTracking: `cart|${name}`,
  ...additional,
});

// This should be a global helper function
export const readJSONFromScriptTag = ({ id }) => {
  try {
    return JSON.parse(document.querySelector(`#${id}`).textContent);
  } catch (err) {
    return {};
  }
};

export const getCurrencyCode = ({ retailers }) =>
  R.compose(
    R.reduce((currencyCode, retailer) => {
      if (currencyCode !== R.prop('currencyCode', retailer)) {
        currencyCode += R.prop('currencyCode', retailer); // eslint-disable-line
      }
      return currencyCode;
    }, ''),
    R.defaultTo([])
  )(retailers);
