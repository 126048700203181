import { TOGGLE_MORE_PRODUCT_RETAILERS } from './types';

const toggleMoreRetailers = (state) => ({
  ...state,
  isOpen: !state.isOpen,
});

const reducer = {
  [TOGGLE_MORE_PRODUCT_RETAILERS]: toggleMoreRetailers,
};

const initialState = {
  isOpen: false,
};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
