import React from 'react';
import R from 'ramda';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import BreakpointProvider from '@cotyorg/ccx-utils/js/components/BreakpointProvider';
import configureStore from './state/store';
import App from './App';
import { saveBasketState, loadBasketState } from './molecules/basket/utils';
import breakpoints from '../styles/_breakpoints.scss';
import { getCartDigitalDataPayload } from './utils/index';

// Main stylesheet - TODO we should split this up to get benefit of component splitting better
import '../styles/main.scss';
import { updateBasket } from './molecules/basket/modules/actions';

const preloadedState = window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// Allow the passed state to be garbage-collected
// https://redux.js.org/recipes/server-rendering#the-client-side
delete window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

const generateCartID = () => {
  const min = 10000000;
  const max = 99999999;
  return `${new Date().getTime()}${
    Math.floor(Math.random() * (max - (min + 1))) + min
  }`;
};

const basketInitialState = loadBasketState() || { basket: {} };
basketInitialState.basket.cartID =
  basketInitialState.basket.cartID || generateCartID();
const store = configureStore({ ...preloadedState, ...basketInitialState });

const getBasketStorageFromEvent = ({ valueName, storageEvent }) =>
  R.compose(
    R.prop('basket'),
    (value) => JSON.parse(value),
    R.prop(valueName)
  )(storageEvent);

if (window) {
  try {
    window.addEventListener('storage', (storageEvent) => {
      const serializedOldBasket = getBasketStorageFromEvent({
        valueName: 'oldValue',
        storageEvent,
      });
      const serializedNewBasket = getBasketStorageFromEvent({
        valueName: 'newValue',
        storageEvent,
      });

      if (
        !R.equals(serializedOldBasket, serializedNewBasket) &&
        serializedNewBasket
      ) {
        store.dispatch(updateBasket({ basket: serializedNewBasket }));
      }
    });
  } catch {
    store.dispatch(updateBasket({ basket: {} }));
  }
}

store.subscribe(() => {
  const { basket } = store.getState();
  if (window.digitalData) {
    window.digitalData.cart = getCartDigitalDataPayload(store.getState());
  }

  saveBasketState({
    basket: {
      cartID: basket.cartID,
      items: basket.items,
      loading: false,
    },
  });
});

const supportsHistory = 'pushState' in window.history;

const renderApp = () => {
  const MOUNT_POINT = document.getElementById('app');

  hydrate(
    <Provider store={store}>
      <BreakpointProvider breakpoints={breakpoints}>
        <BrowserRouter forceRefresh={!supportsHistory}>
          <App />
        </BrowserRouter>
      </BreakpointProvider>
    </Provider>,
    MOUNT_POINT
  );
};

renderApp();
