import { combineReducers } from 'redux';
import { content as contentReducer } from '@cotyorg/ccx-server/modules';
import accordionReducer from '@cotyorg/ccx-molecules/Accordion/modules';
import breakpointReducer from '@cotyorg/ccx-utils/js/components/BreakpointProvider/modules';
import cardoListReducer from '@cotyorg/ccx-organism-cardo-list/modules';
import carouselReducer from '@cotyorg/ccx-organism-carousel/modules';
import cookieReducer from '@cotyorg/ccx-organism-cookie/modules';
import countrySelectorReducer from '@cotyorg/ccx-organism-country-selector/modules';
import dragSwipeReducer from '@cotyorg/ccx-utils/js/components/DragSwipe/modules';
import headerReducer from '@cotyorg/ccx-organism-header/modules';
import lazyLoadReducer from '@cotyorg/ccx-utils/js/components/LazyLoad/modules';
import productSwatchReducer from '@cotyorg/ccx-organism-product-swatch/modules';
import navigationReducer from '@cotyorg/ccx-organism-navigation/modules';
import pictureReducer from '@cotyorg/ccx-atoms/Picture/modules';
import searchReducer from '@cotyorg/ccx-organism-search/modules';
import filterReducer from '@cotyorg/ccx-organism-filter/modules';
import modalReducer from '@cotyorg/ccx-molecules/Modal/modules';
import newsletterReducer from '@cotyorg/ccx-molecules/Newsletter/modules';
import videoReducer from '@cotyorg/ccx-organism-video/modules';
import tabsReducer from '@cotyorg/ccx-molecules/Tabs/modules';
import basketReducer from '../molecules/basket/modules';
import shoppingBagReducer from '../molecules/shopping-bag/modules';
import subcategoryNavigationReducer from '../organisms/SubcategoryNavigation/modules';
import productReducer from '../pages/Product/modules';
import foundationFinderReducer from '../pages/FoundationFinder/modules';
import subscribeProductEmailReducer from '../organisms/SubscribeProductEmail/modules';
import productRetailersReducer from '../organisms/ProductRetailers/modules';

const rootReducer = combineReducers({
  accordions: accordionReducer,
  breakpoints: breakpointReducer,
  cardoList: cardoListReducer,
  carousels: carouselReducer,
  content: contentReducer,
  cookie: cookieReducer,
  countrySelector: countrySelectorReducer,
  dragSwipe: dragSwipeReducer,
  header: headerReducer,
  subcategoryNavigation: subcategoryNavigationReducer,
  lazyLoads: lazyLoadReducer,
  navigation: navigationReducer,
  pictures: pictureReducer,
  filter: filterReducer,
  modals: modalReducer,
  productSwatch: productSwatchReducer,
  search: searchReducer,
  newsletter: newsletterReducer,
  basket: basketReducer,
  shoppingBag: shoppingBagReducer,
  product: productReducer,
  foundationFinder: foundationFinderReducer,
  videos: videoReducer,
  subscribeProductEmail: subscribeProductEmailReducer,
  status: (state = {}) => state,
  presentationLocale: (state = {}) => state,
  productRetailers: productRetailersReducer,
  tabs: tabsReducer,
});

export default rootReducer;
