import reducer from './reducer';

export { getBasketQuantity } from './selectors';
export {
  setError,
  clearError,
  addToBasket,
  removeFromBasket,
  removeFromBasketAction,
  updateBasketItemQuantity,
  updateBasketItemQuantityAction,
  showModal,
  hideModal,
  setProductQuantity,
} from './actions';

export default reducer;
