import R from 'ramda';
import { trackUpdateBasketItemQuantityEvent } from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/basket';
import {
  SET_ERROR,
  CLEAR_ERROR,
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  UPDATE_BASKET_ITEM_QUANTITY,
  SHOW_MODAL,
  HIDE_MODAL,
  SET_PRODUCT_QUANTITY,
  UPDATE_BASKET,
} from './types';
import { getBasketQuantity } from './selectors';
import { getTotalPrice } from '../../shopping-bag/modules';
import { getCurrencyCode } from '../../shopping-bag/utils';

const updateBasket = ({ basket }) => ({
  type: UPDATE_BASKET,
  basket,
});

const setError = ({ message }) => ({
  type: SET_ERROR,
  message,
});

const clearError = () => ({
  type: CLEAR_ERROR,
});

const addToBasket = ({ item }) => ({
  type: ADD_TO_BASKET,
  item,
});

const removeFromBasketAction = ({ ean }) => ({
  type: REMOVE_FROM_BASKET,
  ean,
});

const updateBasketItemQuantityAction = ({ ean, quantity }) => ({
  type: UPDATE_BASKET_ITEM_QUANTITY,
  ean,
  quantity,
});

const trackUpdateBasketItemQuantity = (state, { ean, quantity }) => {
  const index = R.path(['shoppingBag', 'activeRetailer', 'index'], state);
  const total = R.path(['shoppingBag', 'activeRetailer', 'total'], state);
  const items = R.pathOr([], ['basket', 'items'], state);
  const product = items.find((item) => item.ean === ean);
  const activeRetailer = R.path(['shoppingBag', 'activeRetailer'], state);
  const retailers = R.propOr([], 'retailers', product);
  const productQuantity = R.prop('quantity', product);
  const cartID = R.path(['basket', 'cartID'], state);

  let eventAction;
  let eventName;

  if (quantity) {
    eventAction =
      quantity <= productQuantity ? 'Add to Basket' : 'Remove From Basket';
    eventName =
      quantity <= productQuantity ? 'AddtoBasket' : 'RemoveFromBasket';
  } else {
    eventAction = 'Delete From Basket';
    eventName = 'DeleteFromBasket';
  }
  const updatedQuantity =
    quantity <= productQuantity
      ? productQuantity - quantity
      : quantity - productQuantity;

  trackUpdateBasketItemQuantityEvent({
    cartID,
    index,
    total,
    eventAction,
    eventName,
    quantity:
      eventName === 'DeleteFromBasket' ? productQuantity : updatedQuantity,
    currencyCode: getCurrencyCode({ retailers: R.prop('retailers', product) }),
    grandTotal: getTotalPrice(state)[index],
    sumQty: getBasketQuantity(state),
    basePrice: R.compose(
      R.multiply(
        eventName === 'DeleteFromBasket' ? productQuantity : updatedQuantity
      ),
      parseFloat,
      R.prop('price'),
      R.find(R.propEq('name', R.prop('name', activeRetailer)))
    )(retailers),
    sku: R.prop('sku', product),
    unitPrice: R.compose(
      R.prop('price'),
      R.find((retailer) => activeRetailer.name === retailer.name)
    )(retailers),
    displayName: R.prop('displayName', activeRetailer),
    url: R.compose(
      R.prop('deepLinkUrl'),
      R.find((retailer) => activeRetailer.name === retailer.name)
    )(retailers),
    name: R.prop('name', activeRetailer),
    color: R.prop('shadeName', product),
    type: R.prop('subcategory', product),
    category: R.prop('category', product),
    productName: R.prop('name', product),
  });
};

const updateBasketItemQuantity =
  ({ ean, quantity }) =>
  (dispatch, getState) => {
    const prevItemQuantity = R.compose(
      R.prop('quantity'),
      R.find(R.propEq('ean', ean)),
      R.pathOr([], ['basket', 'items'])
    )(getState());
    dispatch(updateBasketItemQuantityAction({ ean, quantity }));
    trackUpdateBasketItemQuantity(getState(), {
      ean,
      quantity: prevItemQuantity,
    });
  };

const removeFromBasket =
  ({ ean }) =>
  (dispatch, getState) => {
    trackUpdateBasketItemQuantity(getState(), { ean });
    dispatch(removeFromBasketAction({ ean }));
  };

const showModal = () => ({ type: SHOW_MODAL });

const hideModal = () => ({ type: HIDE_MODAL });

const setProductQuantity = ({ quantity }) => ({
  type: SET_PRODUCT_QUANTITY,
  quantity,
});

export {
  setError,
  clearError,
  addToBasket,
  removeFromBasketAction,
  removeFromBasket,
  updateBasketItemQuantityAction,
  updateBasketItemQuantity,
  showModal,
  hideModal,
  setProductQuantity,
  updateBasket,
};
